<template>
  <div class="bg-white shadow-md rounded-lg p-6 space-y-6">    
    <div>
      <h4 class="text-lg font-semibold text-gray-700">Description</h4>
      <p class="mt-2 text-gray-600">{{ experiment.description }}</p>
    </div>
    
    <div>
      <h4 class="text-lg font-semibold text-gray-700">Settings</h4>
      <ul class="mt-2 grid grid-cols-2 gap-2">
        <li class="flex items-center">
          <span class="font-medium text-gray-700">Number of Robots:</span>
          <span class="ml-2 text-gray-600">{{ experiment.numRobots }}</span>
        </li>
        <li class="flex items-center">
          <span class="font-medium text-gray-700">Number of Epochs:</span>
          <span class="ml-2 text-gray-600">{{ experiment.numEpochs }}</span>
        </li>
        <template v-if="experiment.isSimulation">
          <li class="flex items-center">
            <span class="font-medium text-gray-700">Number of Obstacles:</span>
            <span class="ml-2 text-gray-600">{{ experiment.numObstacles }}</span>
          </li>
          <li class="flex items-center">
            <span class="font-medium text-gray-700">Number of Objects:</span>
            <span class="ml-2 text-gray-600">{{ experiment.numObjects }}</span>
          </li>
        </template>
      </ul>
    </div>
    
    <div>
      <h4 class="text-lg font-semibold text-gray-700">Results</h4>
      <p v-if="experiment.results" class="mt-2 text-gray-600">{{ experiment.results }}</p>
      <p v-else class="mt-2 text-gray-500 italic">No results available yet.</p>
    </div>
    
    <div>
      <h4 class="text-lg font-semibold text-gray-700">Status</h4>
      <p class="mt-2">
        <span 
          :class="{
            'bg-green-100 text-green-800': experiment.status === 'Completed',
            'bg-yellow-100 text-yellow-800': experiment.status === 'In Progress',
            'bg-red-100 text-red-800': experiment.status === 'Failed'
          }"
          class="px-2 py-1 rounded-full text-sm font-medium"
        >
          {{ experiment.status }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExperimentDetails",
  props: {
    experiment: {
      type: Object,
      required: true,
    },
  },
};
</script>
