export default class MultiRobotEnvironment {
  constructor(numRobots) {
    this.numRobots = numRobots;
    this.robots = Array.from({
      length: numRobots
    }, () => ({
      currentObject: null,
      objects: this.generateObjects(),
      i: 0,
    }));
    this.actionSpace = {
      'class': 'Discrete',
      'n': 4, // Possible actions: forward, backward, left, right
    };
    this.observationSpace = {
      'class': 'Box',
      'shape': [3], // One object represented by [Type, Not Type, Distance]
      'dtype': 'float32'
    };
    this.resets = 0;
    this.currentObject = null; // The single object currently observed
    this.objects = []; // List of all objects in the environment

  }

  generateObjects() {
    let objects = [];
    let numberOfObjects = Math.floor(Math.random() * 10 + 5); // Between 5 and 15 objects
    for (let i = 0; i < numberOfObjects; i++) {
      let type = Math.random() > 0.5 ? 1 : 0; // Randomly target (1) or obstacle (0)
      let distance = Math.random() * 3; // Distance up to 100 meters
      objects.push([type, 1 - type, distance]);
    }
    return objects;
  }

  findClosestObject(robot) {
    if (robot.objects.length === 0) {
      return [0, 0, 0]; // No objects
    }
    robot.objects.sort((a, b) => a[2] - b[2]); // Sort objects by distance
    return robot.objects[0]; // Return the closest one
  }

  async step(actions) {
    const results = this.robots.map((robot, index) => {
      const action = actions[index];
      robot.objects = robot.objects.map(obj => {
        let effect = (action === 1) ? 0.5 : (action === 2 ? -0.5 : 0);
        return [obj[0], obj[1], Math.max(0, obj[2] + effect)]; // No negative distances
      });

      robot.i += 1;
      robot.currentObject = this.findClosestObject(robot);
      let reward = this.calculateReward(robot);
      let done = this.checkIfDone(robot);

      return [robot.currentObject, reward, done];
    });

    return results;
  }

  calculateReward(robot) {
    let reward = 0;
    if (robot.currentObject[0] === 1) {
      reward += 10 / (1 + robot.currentObject[2]);
    }
    if (robot.currentObject[1] === 1) {
      reward -= 20 / (1 + robot.currentObject[2]);
    }
    return reward;
  }

  checkIfDone(robot) {
    return robot.i > 64 || robot.currentObject[2] === 0;
  }

  reset() {
    this.robots.forEach(robot => {
      robot.i = 0;
      robot.objects = this.generateObjects();
      robot.currentObject = this.findClosestObject(robot);
    });
    return this.robots.map(robot => robot.currentObject);
  }
}