<template>
  <form @submit.prevent="submitUser" class="space-y-4">
    <div>
      <label for="username" class="block text-sm font-medium text-gray-700"
        >Username</label
      >
      <input
        type="text"
        id="username"
        v-model="formData.username"
        required
        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
    </div>
    <div>
      <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
      <input
        type="email"
        id="email"
        v-model="formData.email"
        required
        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
    </div>
    <div>
      <label for="password" class="block text-sm font-medium text-gray-700"
        >Password (leave blank to keep current)</label
      >
      <input
        type="password"
        id="password"
        v-model="formData.password"
        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
    </div>
    <div v-if="isAdmin">
      <label for="role" class="block text-sm font-medium text-gray-700">User Role</label>
      <select
        id="role"
        v-model="formData.role"
        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        <option value="user">User</option>
        <option value="admin">Admin</option>
      </select>
    </div>
    <button
      type="submit"
      class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      Update User
    </button>
  </form>
</template>

<script>
import { ref, onMounted } from "vue";
import { updateUser } from "@/services/UserService";

export default {
  name: "UserForm",
  props: {
    user: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["user-updated"],
  setup(props, { emit }) {
    const formData = ref({
      username: "",
      email: "",
      password: "",
      role: "user",
    });

    onMounted(() => {
      formData.value = { ...props.user, password: "" };
    });

    const submitUser = async () => {
      try {
        const updatedUser = await updateUser(props.user._id, formData.value);
        emit("user-updated", updatedUser);
      } catch (error) {
        console.error("Failed to update user:", error);
        // Handle error (e.g., show error message to user)
      }
    };

    return {
      formData,
      submitUser,
    };
  },
};
</script>
