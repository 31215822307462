// src/main.js
import * as tf from '@tensorflow/tfjs';
import {
    createApp
} from 'vue';
import App from './App.vue';
import router from './router';
import './assets/styles.css';

async function initializeBackend() {
    try {
        await tf.setBackend('webgl'); // Alternatively, use 'wasm' or 'cpu'
        await tf.ready();
        console.log('TensorFlow.js Backend set:', tf.getBackend());
    } catch (error) {
        console.error('Failed to initialize TensorFlow.js backend:', error);
    }
}

initializeBackend().then(() => {
    const app = createApp(App);
    app.use(router);
    app.mount('#app');
});