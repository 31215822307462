import {
  createRouter,
  createWebHistory
} from 'vue-router';
import Home from '@/views/HomePage.vue';
import Login from '@/components/LoginForm.vue';
import AdminPage from '@/views/AdminPage.vue';
import AuthService from '@/services/AuthService';

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminPage,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const currentUser = await AuthService.getCurrentUser();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);

  console.log('Navigation guard triggered');
  console.log('Current user:', currentUser);
  console.log('Requires auth:', requiresAuth);
  console.log('Requires admin:', requiresAdmin);

  if (requiresAuth && !currentUser) {
    console.log('Redirecting to login: Authentication required');
    next('/login');
  } else if (requiresAdmin) {
    console.log('Checking admin access');
    if (!currentUser) {
      console.log('No current user, redirecting to login');
      next('/login');
    } else if (typeof currentUser.role === 'undefined') {
      console.error('User role is undefined');
      next('/'); // Redirect to home if role is undefined
    } else if (currentUser.role !== 'admin') {
      console.log('User is not an admin, redirecting to home');
      next('/');
    } else {
      console.log('Admin access granted');
      next();
    }
  } else if (to.path === '/login' && currentUser) {
    console.log('User already logged in, redirecting to home');
    next('/');
  } else {
    console.log('Proceeding to requested route', to.path);
    next();
  }
});

export default router;