<template>
  <div>
    <div v-if="!isOnline" class="bg-red-500 rounded text-white p-2 text-center mb-4">
      You are currently offline. Please check your internet connection.
    </div>
    <div
      v-if="showConnectionStatus"
      class="connection-status-fixed"
      :class="connectionStatus"
    >
      {{ connectionStatus.charAt(0).toUpperCase() + connectionStatus.slice(1) }}
    </div>
    <div v-if="!hideControlButtons" class="flex gap-2 mb-4">
      <div class="flex gap-2">
        <button
          @click="robots.length > 0 && !isTraining && sendCommand('disperse')"
          :class="{
            'bg-green-500 hover:bg-green-700 cursor-pointer':
              robots.length > 0 && !isTraining && !isCommandLooping,
            'bg-green-300 cursor-not-allowed':
              robots.length === 0 || isTraining || isCommandLooping,
          }"
          class="text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          :disabled="isTraining || isCommandLooping"
        >
          Disperse
        </button>
        <button
          @click="robots.length > 0 && !isTraining && sendCommand('gather')"
          :class="{
            'bg-yellow-500 hover:bg-yellow-700 cursor-pointer':
              robots.length > 0 && !isTraining && !isCommandLooping,
            'bg-yellow-300 cursor-not-allowed':
              robots.length === 0 || isTraining || isCommandLooping,
          }"
          class="text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          :disabled="isTraining || isCommandLooping"
        >
          Gather
        </button>
        <button
          @click="robots.length > 0 && !isTraining && sendCommand('stop')"
          :class="{
            'bg-red-500 hover:bg-red-700 cursor-pointer':
              robots.length > 0 && !isTraining,
            'bg-red-300 cursor-not-allowed': robots.length === 0 || isTraining,
          }"
          class="text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          :disabled="isTraining"
        >
          Stop All
        </button>
        <button
          @click="robots.length > 0 && !isTraining && sendCommand('capture-image')"
          :class="{
            'bg-purple-500 hover:bg-purple-700 cursor-pointer':
              robots.length > 0 && !isTraining,
            'bg-purple-300 cursor-not-allowed': robots.length === 0 || isTraining,
          }"
          class="text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          :disabled="isTraining"
        >
          <i class="fas fa-camera"></i> Capture Images
        </button>
      </div>
    </div>

    <!-- Test Mode Indicator and Open Simulator Button -->
    <div class="flex items-center justify-start p-4">
      <label for="toggleTestMode" class="flex items-center cursor-pointer">
        <div class="relative">
          <input
            id="toggleTestMode"
            type="checkbox"
            class="hidden"
            v-model="isTestMode"
            @change="setSimulationMode(isTestMode)"
          />
          <div class="toggle-path bg-gray-200 w-9 h-5 rounded-full shadow-inner"></div>
          <div
            class="toggle-circle absolute top-0.5 left-0.5 w-4 h-4 rounded-full transition bg-white shadow inset-y-0"
          ></div>
        </div>
        <div class="ml-3 text-gray-700 font-medium">Simulation Mode</div>
      </label>
    </div>

    <!-- Robot Grid -->
    <div
      class="mt-6 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4"
    >
      <div
        v-for="robot in filteredRobots"
        :key="robot.id"
        class="robot bg-white shadow-lg rounded-lg overflow-hidden p-3"
        :class="{ 'selected-robot': selectedRobot === robot.id }"
        @click="!isTraining && selectRobot(robot.id)"
      >
        <div class="bg-gray-800 p-2 text-white font-mono text-sm robot-title">
          Robot {{ robot.id }}
        </div>
        <div class="canvas-overlay-container relative">
          <img
            :src="robot.image_data || DEFAULT_ROBOT_IMAGE"
            alt="Robot Image"
            class="robot-image"
          />
          <canvas
            :id="'canvas-' + robot.id"
            width="240"
            height="240"
            class="robot-canvas absolute top-0 left-0 w-full h-full"
          ></canvas>
        </div>
        <div class="robot-details p-4 font-mono text-black">
          <div>Status: {{ robot.state }}</div>
          <div>Distance travelled: {{ robot.totalDistance }}</div>
          <div>Distance to nearest object: {{ robot.distance }}</div>
        </div>
        <div class="control-buttons">
          <button
            @click="!isTraining && sendControlCommand(robot.id, 'turn-left:1000')"
            class="control-button"
            aria-label="Move Left"
            :disabled="isTraining"
          >
            <i class="fas fa-arrow-left"></i>
          </button>
          <button
            @click="!isTraining && sendControlCommand(robot.id, 'turn-right:1000')"
            class="control-button"
            aria-label="Move Right"
            :disabled="isTraining"
          >
            <i class="fas fa-arrow-right"></i>
          </button>
          <button
            @click="!isTraining && sendControlCommand(robot.id, 'move-forward:1000')"
            class="control-button"
            aria-label="Move Forward"
            :disabled="isTraining"
          >
            <i class="fas fa-arrow-up"></i>
          </button>
          <button
            @click="!isTraining && sendControlCommand(robot.id, 'move-backward:1000')"
            class="control-button"
            aria-label="Move Backward"
            :disabled="isTraining"
          >
            <i class="fas fa-arrow-down"></i>
          </button>
          <button
            @click="!isTraining && sendControlCommand(robot.id, 'stop')"
            class="control-button"
            aria-label="Stop"
            :disabled="isTraining"
          >
            <i class="fas fa-stop"></i>
          </button>
          <button
            @click="!isTraining && sendControlCommand(robot.id, 'capture-image')"
            class="control-button"
            aria-label="Capture Image"
            :disabled="isTraining"
          >
            <i class="fas fa-camera"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- Log Terminal -->
    <div
      class="mt-6 bg-terminal-black text-terminal-green shadow-lg rounded-lg overflow-hidden font-mono"
    >
      <div class="bg-gray-800 p-2 text-white">Logs</div>
      <div class="logs">
        <div class="log-entry" v-for="log in logs" :key="log.id">
          <div :class="`log-${log.type}`">
            <i v-if="log.testMode" class="fas fa-flask" style="color: violet"></i>
            <!-- Test mode icon -->
            <i :class="`fas ${getIcon(log.type)}`"></i>
            <span class="timestamp">{{ log.timestamp }}</span>
            <span class="message">{{ log.message }}</span>
            <template v-if="log.topic">
              | <span class="topic">{{ log.topic }}</span>
            </template>
            <template v-if="log.payload">
              | <span class="payload">{{ log.payload }}</span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div id="model-training"></div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  onMounted,
  onBeforeUnmount,
  nextTick,
  computed,
  watch,
} from "vue";
import { Client, Message } from "paho-mqtt";
import * as tf from "@tensorflow/tfjs";
import MultiRobotPPO from "@/classes/MultiRobotPPO";
import MultiRobotEnvironment from "@/classes/MultiRobotEnvironment";
import AuthService from "@/services/AuthService";
import { trainExperiment, updateExperiment } from "@/services/ExperimentService";
import { useRouter } from "vue-router";
import axios from "axios";

// Add this line to define the emit function
const emit = defineEmits([
  "training-started",
  "training-stopped",
  "experiment-updated",
  "experiment-added",
  "training-error",
]);

const router = useRouter();

const policyModel = ref(null);
const valueModel = ref(null);
const agent = ref(null);
const logs = ref([]);
const robots = reactive([]);
const isTestMode = ref(localStorage.getItem("simulationMode") === "true");
const client = ref(null);
const statuses = ["initializing", "dispersing", "gathering", "stopped"];
const robotIntervals = reactive({});
const logId = ref(0);
const isOnline = ref(navigator.onLine);
const reconnectInterval = ref(null);
const mainTopic = ref(isTestMode.value ? "robotswarm-sim" : "robotswarm");
const isConnected = ref(false);
const env = ref(new MultiRobotEnvironment(3));
const connectionStatus = ref("disconnected");

const currentUser = computed(() => AuthService.getCurrentUser().value);
const isTfReady = ref(false);

const isCommandLooping = ref(false);
let commandInterval = null;

const INITIAL_RETRY_DELAY = 1000; // 1 second
const DEFAULT_ROBOT_IMAGE = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFUlEQVR42mP8/5+hnoEIwDiqkL4KAcT9GO0U4BxoAAAAAElFTkSuQmCC`;

let retryAttempts = 0;
const MAX_RETRY_ATTEMPTS = 5;

const isConnecting = ref(false);

const showConnectionStatus = ref(false);
let statusTimeout = null;

watch(connectionStatus, () => {
  showConnectionStatus.value = true;
  clearTimeout(statusTimeout);
  statusTimeout = setTimeout(() => {
    showConnectionStatus.value = false;
  }, 5000);
});

const connectWithRetry = async (retryCount = MAX_RETRY_ATTEMPTS) => {
  if (isConnecting.value) {
    console.log("Connection attempt already in progress. Skipping.");
    return;
  }

  if (retryCount <= 0) {
    console.error("Max retries reached. Unable to connect to MQTT broker.");
    connectionStatus.value = "disconnected";
    isConnecting.value = false;
    router.push("/login");
    return;
  }

  isConnecting.value = true;
  connectionStatus.value = "connecting";

  try {
    await connectMQTT();
    isConnecting.value = false;
  } catch (error) {
    console.error("Connection attempt failed:", error);
    connectionStatus.value = "disconnected";

    if (error === "No auth token") {
      console.error("No authentication token found. Logging out...");
      await AuthService.logout();
      router.push("/login");
      return; // Exit the function to prevent further retries
    }

    const delay = INITIAL_RETRY_DELAY * Math.pow(2, MAX_RETRY_ATTEMPTS - retryCount);
    console.log(
      `Retrying in ${delay / 1000} seconds... (${retryCount - 1} attempts left)`
    );
    setTimeout(() => {
      isConnecting.value = false;
      connectWithRetry(retryCount - 1);
    }, delay);
  }
};

const initializeTensorFlow = async () => {
  if (!isTfReady.value) {
    try {
      await tf.ready();
      if (!tf.getBackend()) {
        await tf.setBackend("webgl");
      }
      isTfReady.value = true;
      console.log("TensorFlow.js backend initialized:", tf.getBackend());
    } catch (error) {
      console.error("Failed to initialize TensorFlow.js:", error);
      isTfReady.value = false;
    }
  }
};

const saveModels = async () => {
  if (!isTfReady.value) {
    console.error("TensorFlow.js is not ready. Cannot save models.");
    return;
  }

  try {
    if (policyModel.value && valueModel.value) {
      await policyModel.value.save("indexeddb://policy-model");
      await valueModel.value.save("indexeddb://value-model");
      console.log("Models saved successfully");
    } else {
      console.error("No models found to save.");
    }
  } catch (error) {
    console.error("Error saving models:", error);
  }
};

const loadModels = async () => {
  if (!isTfReady.value) {
    console.error("TensorFlow.js is not ready. Cannot load models.");
    return;
  }

  try {
    policyModel.value = await tf.loadLayersModel("indexeddb://policy-model");
    valueModel.value = await tf.loadLayersModel("indexeddb://value-model");
    console.log("Models loaded successfully");
    initializeAgent();
  } catch (error) {
    console.warn("Error loading models:", error);
    console.log("Initializing new models");
    await initializeModels();
    initializeAgent();
  }
};

const initializeAgent = async () => {
  if (!isTfReady.value) {
    console.error("TensorFlow.js is not ready. Cannot initialize agent.");
    return;
  }

  agent.value = new MultiRobotPPO(env.value, {
    policyModel: policyModel.value,
    valueModel: valueModel.value,
    nSteps: 128,
    nEpochs: 10,
    verbose: 1,
    numRobots: 3,
  });
  console.log("PPO initialized with loaded models");

  env.value.reset();
};

const initializeModels = async () => {
  await ensureTFBackend();
  const config = {
    inputShape: 4,
    units: 64,
    outputUnits: 2,
  };

  console.log("Initializing new PPO models with config:", config);

  policyModel.value = tf.sequential();
  policyModel.value.add(
    tf.layers.dense({
      inputShape: [config.inputShape],
      units: config.units,
      activation: "relu",
    })
  );
  policyModel.value.add(
    tf.layers.dense({
      units: config.outputUnits,
      activation: "softmax",
    })
  );
  policyModel.value.compile({
    optimizer: tf.train.adam(),
    loss: "categoricalCrossentropy",
  });

  valueModel.value = tf.sequential();
  valueModel.value.add(
    tf.layers.dense({
      inputShape: [config.inputShape],
      units: config.units,
      activation: "relu",
    })
  );
  valueModel.value.add(
    tf.layers.dense({
      units: 1,
      activation: "linear",
    })
  );
  valueModel.value.compile({
    optimizer: tf.train.adam(),
    loss: "meanSquaredError",
  });

  console.log("Policy and value models initialized and compiled.");
};

async function ensureTFBackend() {
  if (!tf.engine().backend) {
    await tf.setBackend("webgl");
    await tf.ready();
    console.log("Ensured TensorFlow.js backend:", tf.getBackend());
  }
}

async function learn() {
  try {
    await agent.value.learn({
      totalTimesteps: 1,
      callback: {
        onTrainingStart: function (p) {
          console.log(p.config);
        },
      },
    });
  } catch (error) {
    console.error("Learning failed:", error);
  }
}

const setSimulationMode = (mode) => {
  isTestMode.value = mode;
  mainTopic.value = mode ? "robotswarm-sim" : "robotswarm";
  localStorage.setItem("simulationMode", mode);
  console.log(
    `Switched to ${mode ? "simulation" : "production"} mode. Main topic is now '${
      mainTopic.value
    }'.`
  );
};

const isTraining = ref(false);

const sendExperimentCommand = (experimentId, action) => {
  if (!client.value || !client.value.isConnected()) {
    console.error("MQTT client is not connected");
    return;
  }

  const topic = `${mainTopic.value}/command/experiment`;
  const payload = JSON.stringify({
    experiment_id: experimentId,
    action: action,
  });

  const message = new Message(payload);
  message.destinationName = topic;
  message.qos = 1;

  client.value.send(message);
  console.log(`Sent experiment ${action} command for experiment ${experimentId}`);
  addLog("send", `Experiment ${action} command`, topic, payload);
};

const handleExperimentAdded = async () => {
  showExperimentForm.value = false;
};

const startExperimentTraining = async (experiment, isRetrain = false) => {
  console.log("MQTTIntegration: startExperimentTraining called", experiment, isRetrain);

  // Close all current robots
  closeAllRobots();

  if (!experiment.isSimulation) {
    const connectedRobots = robots.length;
    if (connectedRobots !== experiment.numRobots) {
      console.error(
        `Cannot start real-world training. Expected ${experiment.numRobots} robots, but ${connectedRobots} are connected.`
      );
      emit(
        "training-error",
        `Cannot start real-world training. Expected ${experiment.numRobots} robots, but ${connectedRobots} are connected.`
      );
      return;
    }
  }

  // Create a new object with writable properties
  currentExperiment.value = {
    ...experiment,
    numRobots: experiment.numRobots,
    numObstacles: experiment.isSimulation ? experiment.numObstacles : 0,
    numObjects: experiment.isSimulation ? experiment.numObjects : 0,
  };

  console.log("Current experiment set:", currentExperiment.value);
  setSimulationMode(experiment.isSimulation);

  if (experiment.isSimulation) {
    console.log("MQTTIntegration: Emitting training-started event", experiment);
    emit("training-started", experiment);
    // Send MQTT command to start the experiment
    sendExperimentCommand(experiment._id, "start");
  } else {
    console.log(
      "MQTTIntegration: Not a simulation experiment, training-started event not emitted"
    );
  }

  try {
    isTraining.value = true;
    if (isRetrain) {
      // If it's a retrain, we might want to reset some values
      experiment.currentEpoch = 0;
      experiment.progress = 0;
      // You might want to keep the previous reward and accuracy, or reset them as well
    }
    await runExperimentTraining(isRetrain);
  } catch (error) {
    console.error("Error in runExperimentTraining:", error);
    // Handle the error appropriately
  } finally {
    isTraining.value = false;
  }
};

// Add this new function to close all robots
const closeAllRobots = () => {
  console.log("Closing all current robots");

  // If in simulation mode, simply clear the robots array
  if (isTestMode.value) {
    robots.splice(0, robots.length);
    console.log("All simulated robots removed");
  } else {
    // For real robots, send a disconnect command to each robot
    robots.forEach((robot) => {
      sendControlCommand(robot.id, "disconnect");
    });
    // Then clear the robots array
    robots.splice(0, robots.length);
  }

  // Unsubscribe from all robot-specific topics
  if (client.value && client.value.isConnected()) {
    robots.forEach((robot) => {
      const topic = `${mainTopic.value}/response/robot/${robot.id}`;
      client.value.unsubscribe(topic);
      console.log(`Unsubscribed from topic: ${topic}`);
    });
  }

  console.log("All robots closed and removed");
};

const runExperimentTraining = async (isRetrain = false) => {
  console.log("MQTTIntegration: runExperimentTraining called", isRetrain);
  if (!currentExperiment.value) {
    console.error(
      "MQTTIntegration: No current experiment, exiting runExperimentTraining"
    );
    return;
  }

  const experimentId = currentExperiment.value._id;
  const { numEpochs, numRobots, numObstacles, numObjects } = currentExperiment.value;
  console.log("Experiment details:", { numEpochs, numRobots, numObstacles, numObjects });

  if (!numEpochs || isNaN(numEpochs) || numEpochs <= 0) {
    console.error("Invalid number of epochs:", numEpochs);
    return;
  }

  const totalEpochs = parseInt(numEpochs, 10);
  console.log(`Starting training with ${totalEpochs} epochs`);

  try {
    for (let epoch = 0; epoch < totalEpochs; epoch++) {
      console.log(`Starting epoch ${epoch + 1} of ${totalEpochs}`);

      // Simulate some training time
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds

      // Replace the existing check with this:
      if (!currentExperiment.value || currentExperiment.value.status === "Stopped") {
        break;
      }

      currentExperiment.value.numRobots = numRobots;
      currentExperiment.value.numObstacles = numObstacles;
      currentExperiment.value.numObjects = numObjects;
      currentExperiment.value.currentEpoch = epoch + 1;

      // Initialize the RL agent
      await initializeAgent();

      // Run the learning process
      try {
        await learn();
      } catch (error) {
        console.error("Error during learning process:", error);
      }

      // Calculate reward and accuracy
      const reward = calculateReward();
      const accuracy = calculateAccuracy();

      // Update the experiment progress, reward, and accuracy
      try {
        const trainingData = {
          progress: (epoch + 1) / totalEpochs,
          currentEpoch: epoch + 1,
          reward: reward,
          accuracy: accuracy,
          status: "Running",
        };
        const updatedExperiment = await trainExperiment(experimentId, trainingData);
        console.log(`Updated experiment for epoch ${epoch + 1}`);

        // Emit experiment-updated event
        if (!(!currentExperiment.value || currentExperiment.value.status === "Stopped")) {
          emit("experiment-updated", updatedExperiment);
        }
      } catch (error) {
        console.error("Error updating experiment:", error);
      }

      console.log(`Completed epoch ${epoch + 1} of ${totalEpochs}`);
      console.log(`Reward: ${reward}, Accuracy: ${accuracy}`);

      // Force refresh the iframe at each epoch
      emit("refresh-simulator");

      if (!currentExperiment.value) {
        break;
      }
    }

    // After the loop completes, update the experiment status to 'Completed'
    if (currentExperiment.value) {
      console.log("Experiment training completed. Updating status to Completed.");
      const completedExperiment = await updateExperiment(currentExperiment.value._id, {
        status: "Completed",
        currentEpoch: totalEpochs,
        progress: 1, // 100% progress
      });
      emit("experiment-updated", completedExperiment);
      emit("training-stopped", completedExperiment);

      // Clean up robots if it's a simulation
      if (currentExperiment.value.isSimulation) {
        robots.splice(0, robots.length);
        console.log("All simulated robots removed after training completion");
      }

      currentExperiment.value = null;
    }
  } catch (error) {
    console.error("Error during experiment training:", error);
    // Handle the error appropriately
  } finally {
    isTraining.value = false;
    if (currentExperiment.value) {
      const currentEpoch = currentExperiment.value.currentEpoch;
      const totalEpochs = currentExperiment.value.numEpochs;

      console.log(`Current epoch: ${currentEpoch}, Total epochs: ${totalEpochs}`);

      if (currentEpoch >= totalEpochs) {
        console.log("MQTTIntegration: Experiment training completed");
        // This block is now redundant, but we'll keep it as a fallback
        try {
          const completedExperiment = await updateExperiment(
            currentExperiment.value._id,
            {
              status: "Completed",
              currentEpoch: totalEpochs,
              progress: 1,
            }
          );
          emit("experiment-updated", completedExperiment);
          emit("training-stopped", completedExperiment);

          // Clean up robots if it's a simulation
          if (currentExperiment.value.isSimulation) {
            robots.splice(0, robots.length);
            console.log(
              "All simulated robots removed after training completion (fallback)"
            );
          }
        } catch (error) {
          console.error("Error updating experiment status to Completed:", error);
        }
      } else if (currentExperiment.value.status === "Stopped") {
        console.log("MQTTIntegration: Experiment was manually stopped");
        emit("training-stopped", currentExperiment.value);

        // Clean up robots if it's a simulation
        if (currentExperiment.value.isSimulation) {
          robots.splice(0, robots.length);
          console.log("All simulated robots removed after manual stop");
        }
      }
      currentExperiment.value = null;
    } else {
      console.log("MQTTIntegration: currentExperiment is null, training loop ended");
    }
  }
};

// Helper functions to calculate reward and accuracy
const calculateReward = () => {
  // Implement your reward calculation logic here
  // This is a placeholder implementation
  return Math.random(); // Returns a random number between 0 and 1
};

const calculateAccuracy = () => {
  // Implement your accuracy calculation logic here
  // This is a placeholder implementation
  return Math.random(); // Returns a random number between 0 and 1
};

function getRobotStatus(robotId) {
  if (typeof robotId === "object") {
    console.error(
      "getRobotStatus called with an object instead of a string ID:",
      robotId
    );
    return; // Exit if robotId is not a string
  }
  const command = "status"; // This is the command your robot expects
  sendControlCommand(robotId, command);
  console.log(`Status request sent to robot: ${robotId}`); // Ensure robotId is a string
}

watch(isTestMode, (newValue) => {
  console.log("Simulation Mode Changed:", newValue);
  localStorage.setItem("simulationMode", newValue);

  // Clear logs
  logs.value = [];

  // Disconnect existing robots and clear the array
  if (client.value && client.value.isConnected()) {
    robots.forEach((robot) => {
      handleRobotDisconnect(robot.id);
    });
    client.value.disconnect(); // Disconnect the current MQTT client
  }

  // Clear robots array
  robots.splice(0, robots.length);
  console.log("All robots removed before opening simulator");

  // Update the main topic
  mainTopic.value = newValue ? "robotswarm-sim" : "robotswarm";
  console.log(
    `Switched to ${newValue ? "simulation" : "production"} mode. Main topic is now '${
      mainTopic.value
    }'.`
  );

  // Reconnect with the new settings
  connectWithRetry();

  // Additional logging for debugging
  if (!newValue) {
    console.log("Clearing robots");
  }
  console.log("Current Robots:", robots);
});

watch(isOnline, (newVal) => {
  isOnline.value = newVal;
  if (!newVal) {
    console.error("Internet connection is offline.");
  }
});

function connectMQTT() {
  if (!isOnline.value) {
    console.error("Cannot connect to MQTT because the internet is offline.");
    return Promise.reject("Offline");
  }
  const token = AuthService.getToken();
  if (!token) {
    console.error("No authentication token found. Please log in.");
    return Promise.reject("No auth token");
  }
  console.log("MQTT Client starts...");
  client.value = new Client(
    "fb6d6dae8e9948d5b1af0453ac29ae30.s1.eu.hivemq.cloud",
    8884,
    "robot-swarm-master" + new Date().getTime()
  );
  client.value.onConnectionLost = onConnectionLost;
  client.value.onMessageArrived = onMessageArrived;

  const willMessage = new Message(JSON.stringify({ token }));
  willMessage.destinationName = `${mainTopic.value}/disconnect`;
  willMessage.qos = 1;
  willMessage.retained = false;

  return new Promise((resolve, reject) => {
    client.value.connect({
      useSSL: true,
      userName: "csiszi",
      password: "dycdY3-hobfix-rijvux",
      onSuccess: () => {
        onConnect();
        resolve();
      },
      onFailure: (responseObject) => {
        console.error("Connection failed. Error code:", responseObject.errorCode);
        console.error("Error message:", responseObject.errorMessage);
        console.error("Full response object:", responseObject);
        onFail(responseObject);
        reject(responseObject);
      },
      willMessage: willMessage,
    });
  });
}

function extractRobotIdFromTopic(topic) {
  const regex = /^([a-z-:\w]*)\/response\/robot\/([A-Za-z0-9:]+)/;
  const match = topic.match(regex);
  console.log("match", match);
  return match ? match[2] : null; // Returns the robot ID or null if no match is found
}

function escapeRegex(string) {
  // This function escapes special characters that have special meanings in regex
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

async function updateLearningModel() {
  // Check if there are enough transitions to update the model
  if (agent.value) {
    learn(); // This performs the PPO update steps internally
    console.log("Model updated");
  } else {
    console.log("Agent is not initialized or not ready to update");
  }
}

const onMessageArrived = async (message) => {
  const topic = message.destinationName;

  try {
    let payload;
    try {
      payload = JSON.parse(message.payloadString);
      console.log("Json", message.payloadString);
    } catch (error) {
      console.error("Error parsing MQTT message:", error);
      console.log("Message Arrived:", message.payloadString);
      addLog("receive", "", topic, message.payloadString);
      return;
    }

    addLog("receive", "", topic, message.payloadString);

    const robotId = extractRobotIdFromTopic(topic);

    if (robotId && payload.command === "status") {
      updateRobotStatus(robotId, payload.result);
    }

    if (topic === `${mainTopic.value}/logs`) {
      addLog("notice", "", topic, payload.result);
    } else {
      addLog("receive", "", topic, message.payloadString);
    }

    const escapedTopic = escapeRegex(mainTopic.value);
    const regex = /^([a-z-:\w]*)\/response\/robot\/([A-Za-z0-9:]+)/;

    if (
      topic === `${mainTopic.value}/heartbeat` &&
      payload.command &&
      payload.command.startsWith("Disconnect:")
    ) {
      const robotId = payload.result.split(":")[1];
      handleRobotDisconnect(robotId);
    } else if (topic === `${mainTopic.value}/heartbeat`) {
      const robotId = payload.result;
      const isSimulated = isTestMode.value; // Assuming isTestMode indicates simulation
      const ownershipData = await checkRobotOwnership(robotId, isSimulated);
      if (ownershipData.owned) {
        if (!robots.some((robot) => robot.id === robotId)) {
          robots.push({
            id: robotId,
            x: 0,
            y: 0,
            status: statuses[0],
            image_data: DEFAULT_ROBOT_IMAGE,
            totalDistance: 0,
            distance: 0,
            objects: [],
            isSimulated: ownershipData.is_simulated,
            swarmIds: ownershipData.swarm_ids || [], // Add a fallback empty array
          });

          client.value.subscribe(`${mainTopic.value}/response/robot/${robotId}`);
          console.log(
            `Robot connected and subscribed: ${robotId}, Simulated: ${
              ownershipData.is_simulated
            }, Swarms: ${
              ownershipData.swarm_ids ? ownershipData.swarm_ids.join(", ") : "None"
            }`
          );
        }
        getRobotStatus(robotId);
        resetRobotIntervals(robotId);
      } else {
        console.log(`Robot ${robotId} is not owned by the current user. Ignoring.`);
      }
    } else if (regex.test(topic)) {
      const robotId = extractRobotIdFromTopic(topic);
      console.log("escapedTopic", escapedTopic);
      console.log("topic", topic);
      console.log(payload.result, payload.command == "status");
      console.log("robotId", robotId);

      if (robotId) {
        if (
          payload.result &&
          payload.command == "capture-image" &&
          typeof robotId === "string"
        ) {
          const robotIndex = robots.findIndex((robot) => robot.id === robotId);
          if (robotIndex !== -1) {
            robots[
              robotIndex
            ].image_data = `data:image/png;base64,${payload.result.image}`;
            console.log(`Image updated for robot ${robotId}`);
          }
        } else if (
          payload.result &&
          payload.command == "status" &&
          typeof robotId === "string"
        ) {
          const parsedData = payload.result;
          const robotId = extractRobotIdFromTopic(topic);

          const robotIndex = robots.findIndex((r) => r.id === robotId);
          if (robotIndex !== -1) {
            const oldState = { ...robots[robotIndex] }; // Cloning the old state
            console.log("parsedData", parsedData);

            // Update the robot's state only if parsedData.state is valid
            if (parsedData.state !== undefined && parsedData.state !== null) {
              robots[robotIndex].state = parsedData.state;
            }

            // Update other properties
            robots[robotIndex].totalDistance = parsedData.totalDistance;
            robots[robotIndex].distance = parsedData.distance;
            robots[robotIndex].objects = parsedData.objects;

            console.log("Updated robot details", robots[robotIndex]);

            // Update the canvas with new detected objects if they exist
            if (parsedData.objects) {
              updateCanvasWithDetectedObjects(robotId, parsedData.objects);
            }

            // Calculate the reward and update learning model if both old and new states are available
            if (oldState) {
              console.log("Old state and new state available for learning model update");
              updateLearningModel();
            }
          }
        } else {
          console.log("NO TOPIC match.");
        }
      } else {
        console.log("robot id couldn't be extracted.");
      }
    }
  } catch (e) {
    console.error("Error processing message:", e, JSON.stringify(e, null, 2));
    return;
  }
};

function updateRobotStatus(robotId, status) {
  const robotIndex = robots.findIndex(r => r.id === robotId);
  if (robotIndex !== -1) {
    robots[robotIndex] = {
      ...robots[robotIndex],
      state: status.state,
      distance: status.distance,
      totalDistance: status.totalDistance,
      objects: status.objects
    };
    console.log(`Updated status for robot ${robotId}:`, robots[robotIndex]);
  }
}

// Add this new function to update the canvas
function updateCanvasWithDetectedObjects(robotId, objects) {
  const canvas = document.getElementById(`canvas-${robotId}`);
  if (!canvas) {
    console.error(`Canvas for robot ${robotId} not found`);
    return;
  }

  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  objects.forEach((obj) => {
    const x = obj.x * canvas.width;
    const y = obj.y * canvas.height;
    const width = obj.width * canvas.width;
    const height = obj.height * canvas.height;

    // Determine if the object is a robot or not
    const isRobot = obj.type === "robot";

    // Set colors based on object type
    ctx.strokeStyle = isRobot ? "rgb(255, 20, 147)" : "cyan"; // Bright pink for robots, cyan for other objects
    ctx.lineWidth = 2;
    ctx.strokeRect(x - width / 2, y - height / 2, width, height);

    // Draw center point
    const centerX = x;
    const centerY = y;
    const radius = 5; // 5px radius for center point

    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    ctx.fillStyle = isRobot ? "rgb(255, 105, 180)" : "red"; // Hot pink fill for robots, red for other objects
    ctx.fill();
    ctx.strokeStyle = "yellow";
    ctx.lineWidth = 2;
    ctx.stroke();
    ctx.closePath();

    // Add label
    ctx.fillStyle = isRobot ? "rgb(255, 20, 147)" : "cyan";
    ctx.font = "12px Arial";
    ctx.fillText(obj.type || "Object", x - width / 2, y - height / 2 - 5);
  });
}

function onConnectionLost(responseObject) {
  if (responseObject.errorCode !== 0) {
    console.error("Connection lost. Error code:", responseObject.errorCode);
    console.error("Error message:", responseObject.errorMessage);
    isConnected.value = false;
    connectionStatus.value = "disconnected";
    addLog("error", `Connection lost: ${responseObject.errorMessage}`);

    if (isOnline.value && !isConnecting.value && retryAttempts < MAX_RETRY_ATTEMPTS) {
      console.log(
        `Attempting to reconnect... (Attempt ${
          retryAttempts + 1
        } of ${MAX_RETRY_ATTEMPTS})`
      );
      connectionStatus.value = "reconnecting";
      retryAttempts++;
      connectWithRetry(MAX_RETRY_ATTEMPTS - retryAttempts);
    } else if (retryAttempts >= MAX_RETRY_ATTEMPTS) {
      console.log("Max retry attempts reached. Please try reconnecting manually.");
      addLog("error", "Max retry attempts reached. Please try reconnecting manually.");
    } else {
      console.log(
        "Device is offline or connection attempt already in progress. Will attempt to reconnect when online and not connecting."
      );
    }
  }
}

function onConnect() {
  console.log("MQTT Connected");
  isConnected.value = true;
  connectionStatus.value = "connected";
  addLog("notice", "MQTT Connected");
  clearInterval(reconnectInterval.value);
  reconnectInterval.value = null;
  retryAttempts = 0; // Reset retry attempts

  subscribeToTopics();
  sendCommand("ping");
}

function subscribeToTopics() {
  if (!ensureConnected()) return;

  const topicsToSubscribe = [
    `${mainTopic.value}/logs`,
    `${mainTopic.value}/response/swarm`,
    `${mainTopic.value}/heartbeat`,
  ];

  topicsToSubscribe.forEach((topic) => {
    if (client.value && client.value.isConnected()) {
      addLog("subscribe", "Subscribe", topic, "");
      client.value.subscribe(topic);
    } else {
      console.error(`Failed to subscribe to ${topic}: MQTT client not connected`);
    }
  });
}

function onFail(responseObject) {
  console.error("Failed to connect. Error code:", responseObject.errorCode);
  console.error("Error message:", responseObject.errorMessage);
  connectionStatus.value = "disconnected";
  addLog("error", `Connection failed: ${responseObject.errorMessage}`);
}

function ensureConnected() {
  if (!client.value || !client.value.isConnected()) {
    if (retryAttempts < MAX_RETRY_ATTEMPTS) {
      retryAttempts = retryAttempts + 1;
    }
    console.error(
      "MQTT client is not connected. Attempting to reconnect...",
      retryAttempts
    );
    connectWithRetry(MAX_RETRY_ATTEMPTS - retryAttempts);
    return false;
  }
  return true;
}

function getIcon(type) {
  switch (type) {
    case "error":
      return "fa-exclamation-circle";
    case "subscribe":
      return "fa-info-circle";
    case "unsubscribe":
      return "fa-info-circle";
    case "notice":
      return "fa-info-circle";
    case "send":
      return "fa-paper-plane";
    case "receive":
      return "fa-inbox";
    default:
      return "fa-circle";
  }
}

function addLog(type, message, topic = "", payloadString = "") {
  const timestamp = new Date().toISOString();
  let formattedPayload = payloadString;
  try {
    if (payloadString && typeof payloadString === "string") {
      let parsedData;
      try {
        parsedData = JSON.parse(payloadString);
      } catch {
        parsedData = payloadString;
      }
      formattedPayload = JSON.stringify(parsedData, null, 2); // Beautify JSON

      if (formattedPayload.length > 150) {
        formattedPayload = formattedPayload.substring(0, 147) + "...";
      }
    }
  } catch {
    // If it's not JSON, leave as is
  }

  const logEntry = {
    id: logId.value++,
    type,
    message: message,
    topic: topic || "",
    payload: formattedPayload || "",
    timestamp,
    testMode: isTestMode.value,
  };

  logs.value.push(logEntry);
  nextTick(scrollToBottom);
}

function sendCommand(command) {
  console.log(`sendCommand called with: ${command}`);
  if (!ensureConnected()) return;

  if (robots.length === 0) {
    console.warn("No connected robots found");
    return;
  }

  if (command === "disperse" || command === "gather") {
    isCommandLooping.value = true;
    sendRandomCommands(command);
  } else if (command === "stop") {
    isCommandLooping.value = false;
    if (commandInterval) {
      clearInterval(commandInterval);
      commandInterval = null;
    }
    sendStopCommand();
  } else {
    robots.forEach((robot) => {
      if (robot.id) {
        sendControlCommand(robot.id, command);
      }
    });
  }
}

function sendRandomCommands(mode) {
  console.log(`sendRandomCommands called with mode: ${mode}`);
  const commands = ["move-forward", "move-backward", "turn-left", "turn-right"];

  if (commandInterval) {
    clearInterval(commandInterval);
    commandInterval = null;
  }

  let loopCount = 0;
  const maxLoops = 100; // Adjust this value as needed

  const sendCommandToRobot = (robot, index) => {
    if (robot.id) {
      const randomCommand = commands[Math.floor(Math.random() * commands.length)];
      const duration =
        mode === "disperse"
          ? Math.floor(Math.random() * 2000) + 1000 // 1-3 seconds for disperse
          : Math.floor(Math.random() * 1000) + 500; // 0.5-1.5 seconds for gather

      const fullCommand = `${randomCommand}:${duration}`;
      sendControlCommand(robot.id, fullCommand);

      // Schedule next command for this robot
      setTimeout(() => {
        if (isCommandLooping.value && loopCount < maxLoops) {
          sendCommandToRobot(robot, index);
        }
      }, duration + 200); // Add 200ms buffer between commands
    }
  };

  // Start the command loop for each robot
  robots.forEach((robot, index) => {
    sendCommandToRobot(robot, index);
  });

  // Set an interval to check if we should stop the loop
  commandInterval = setInterval(() => {
    loopCount++;
    if (!isCommandLooping.value || loopCount >= maxLoops) {
      clearInterval(commandInterval);
      commandInterval = null;
      isCommandLooping.value = false;
      console.log("Random command loop ended");
    }
  }, 1000); // Check every second
}

const sendStopCommand = () => {
  console.log('sendStopCommand called');
  if (robots.length === 0) return;
  robots.forEach((robot) => {
    if (robot.id) {
      sendControlCommand(robot.id, "stop");
    }
  });
};

function sendControlCommand(robotId, command) {
  if (!ensureConnected()) return;

  const topic = `${mainTopic.value}/command/robot/${robotId}`;
  const json_command = JSON.stringify({ command });
  const message = new Message(json_command);
  message.destinationName = topic;

  addLog("send", "", topic, command);
  client.value.send(message);

  // Reset the robot's image to default after sending a movement command
  if (command.startsWith("move") || command.startsWith("turn")) {
    const robotIndex = robots.findIndex((robot) => robot.id === robotId);
    if (robotIndex !== -1) {
      robots[robotIndex].image_data = DEFAULT_ROBOT_IMAGE;
    }
  }
}

function scrollToBottom() {
  const logContainer = document.querySelector(".logs");
  if (logContainer) {
    logContainer.scrollTop = logContainer.scrollHeight;
  }
}

function handleRobotDisconnect(robotId) {
  console.log(`No heartbeat received for 40 seconds. Disconnecting robot: ${robotId}`);
  const index = robots.findIndex((robot) => robot.id === robotId);
  if (index !== -1) {
    addLog(
      "unsubscribe",
      "Unsubscribed",
      `${mainTopic.value}/response/robot/${robotId}`,
      ""
    );
    client.value.unsubscribe(`${mainTopic.value}/response/robot/${robotId}`);
    addLog("unsubscribe", "Disconnected", `${robotId}`, "");
    robots.splice(index, 1); // Remove the robot from the list
  }

  if (robotIntervals[robotId]) {
    if (robotIntervals[robotId].heartbeatTimer) {
      clearTimeout(robotIntervals[robotId].heartbeatTimer);
    }
    delete robotIntervals[robotId];
  }
}

function resetRobotIntervals(robotId) {
  if (!robotIntervals[robotId]) {
    robotIntervals[robotId] = {};
  }

  if (robotIntervals[robotId].heartbeatTimer) {
    clearTimeout(robotIntervals[robotId].heartbeatTimer);
  }

  robotIntervals[robotId].heartbeatTimer = setTimeout(() => {
    handleRobotDisconnect(robotId);
  }, 25000); // 25 seconds
}

const handleUserLogin = () => {
  console.log("User logged in, reconnecting MQTT...");
  if (client.value && client.value.isConnected()) {
    client.value.disconnect();
  }
  // Load all settings from localStorage after login
  isTestMode.value = localStorage.getItem("simulationMode") === "true";
  ensureConnected();
};

const handleUserLogout = (event) => {
  console.log("User logout event received, disconnecting MQTT...");
  const logoutSuccess = event.detail.success;

  if (setupMQTT.value) {
    if (setupMQTT.value.client.value && setupMQTT.value.isConnected.value) {
      setupMQTT.value.client.value.disconnect();
    }
    // Clear all robot data
    robots.splice(0, robots.length);
    // Clear all intervals
    Object.values(setupMQTT.value.robotIntervals).forEach((interval) => {
      if (interval.heartbeatTimer) {
        clearTimeout(interval.heartbeatTimer);
      }
    });
    setupMQTT.value.robotIntervals = {};
    // Reset connection status
    connectionStatus.value = "disconnected";
    // Clear logs if needed
    logs.value = [];
  }

  if (!logoutSuccess) {
    console.warn(
      "Logout was not successful, but MQTT connection has been terminated for security."
    );
    // You might want to add some user feedback here about the failed logout
  }
};

const handleLoginSuccess = () => {
  console.log("Login successful, connecting to MQTT...");
  connectMQTT();
};

const filteredRobots = computed(() => robots.filter((robot) => robot.id));

// Wrap all the code that depends on the user being logged in inside a computed
const setupMQTT = computed(() => {
  if (!currentUser.value) return null;

  // Return an object with all the necessary setup
  return {
    policyModel: null,
    valueModel: null,
    agent: ref(null),
    logs: ref([]),
    robots: reactive([]),
    isTestMode: ref(false),
    client: ref(null),
    statuses: ["initializing", "dispersing", "gathering", "stopped"],
    robotIntervals: reactive({}),
    logId: ref(0),
    isOnline: ref(navigator.onLine),
    reconnectInterval: ref(null),
    mainTopic: ref(isTestMode.value ? "robotswarm-sim" : "robotswarm"),
    isConnected: ref(false),
    env: ref(new MultiRobotEnvironment(ref(5))),

    // Include all the existing methods here
    saveModels,
    loadModels,
    initializeAgent,
    ensureTFBackend,
    initializeModels,
    learn,
    getRobotStatus,
    connectMQTT,
    extractRobotIdFromTopic,
    escapeRegex,
    updateLearningModel,
    onMessageArrived,
    onConnect,
    subscribeToTopics,
    onFail,
    onConnectionLost,
    ensureConnected,
    getIcon,
    addLog,
    sendCommand,
    sendControlCommand,
    scrollToBottom,
    handleRobotDisconnect,
    resetRobotIntervals,
    handleUserLogin,
    handleUserLogout,

    // ... other methods ...
  };
});

// Use a watch to react to changes in the setupMQTT computed
watch(
  setupMQTT,
  (newSetup, oldSetup) => {
    if (oldSetup) {
      // Clean up the old setup
      if (
        oldSetup.client &&
        oldSetup.client.value &&
        oldSetup.client.value.isConnected()
      ) {
        oldSetup.client.value.disconnect();
      }
      // Clear any intervals, timeouts, etc.
      Object.values(oldSetup.robotIntervals).forEach((interval) => {
        if (interval.heartbeatTimer) {
          clearTimeout(interval.heartbeatTimer);
        }
      });
    }

    if (newSetup) {
      ensureConnected();
    }
  },
  { immediate: true }
);

const handleInitiateMQTTConnection = () => {
  console.log("Initiating MQTT connection...");
  connectWithRetry(MAX_RETRY_ATTEMPTS - retryAttempts);
};

const handleOnline = () => {
  isOnline.value = true;
  connectWithRetry(MAX_RETRY_ATTEMPTS - retryAttempts); // Reset retry count when coming back online
};

const handleOffline = () => {
  isOnline.value = false;
};

const selectedRobot = ref(null);
const isExperimentFormOpen = ref(false);

const selectRobot = (robotId) => {
  selectedRobot.value = robotId;
};

const pressedKeys = new Set();

const handleKeyDown = (event) => {
  if (isExperimentFormOpen.value) return;

  if (
    ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", " ", "Enter", "Tab"].includes(
      event.key
    )
  ) {
    event.preventDefault();
    pressedKeys.add(event.key);
    updateRobotMovement();
  }

  if (event.key === "Tab") {
    event.preventDefault();
    handleTabNavigation(event.shiftKey);
  }
};

const handleKeyUp = (event) => {
  if (isExperimentFormOpen.value) return;

  if (
    ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", " ", "Enter"].includes(event.key)
  ) {
    pressedKeys.delete(event.key);
    if (pressedKeys.size === 0) {
      sendControlCommand(selectedRobot.value, "stop");
    } else {
      updateRobotMovement();
    }
  }
};

const updateRobotMovement = () => {
  if (!selectedRobot.value) return;

  const forward = pressedKeys.has("ArrowUp");
  const backward = pressedKeys.has("ArrowDown");
  const left = pressedKeys.has("ArrowLeft");
  const right = pressedKeys.has("ArrowRight");

  if (forward && left) {
    sendControlCommand(selectedRobot.value, "turn-left:1000");
  } else if (forward && right) {
    sendControlCommand(selectedRobot.value, "turn-right:1000");
  } else if (backward && left) {
    sendControlCommand(selectedRobot.value, "turn-right:1000");
  } else if (backward && right) {
    sendControlCommand(selectedRobot.value, "turn-left:1000");
  } else if (forward) {
    sendControlCommand(selectedRobot.value, "move-forward:1000");
  } else if (backward) {
    sendControlCommand(selectedRobot.value, "move-backward:1000");
  } else if (left) {
    sendControlCommand(selectedRobot.value, "turn-left:1000");
  } else if (right) {
    sendControlCommand(selectedRobot.value, "turn-right:1000");
  }

  if (pressedKeys.has(" ")) {
    sendControlCommand(selectedRobot.value, "stop");
  }
  if (pressedKeys.has("Enter")) {
    sendControlCommand(selectedRobot.value, "capture-image");
  }
};

const handleTabNavigation = (isShiftPressed) => {
  const robotIds = filteredRobots.value.map((robot) => robot.id);
  if (robotIds.length === 0) return;

  if (!selectedRobot.value) {
    selectedRobot.value = isShiftPressed ? robotIds[robotIds.length - 1] : robotIds[0];
    return;
  }

  const currentIndex = robotIds.indexOf(selectedRobot.value);
  let nextIndex;

  if (isShiftPressed) {
    nextIndex = currentIndex > 0 ? currentIndex - 1 : robotIds.length - 1;
  } else {
    nextIndex = currentIndex < robotIds.length - 1 ? currentIndex + 1 : 0;
  }

  selectedRobot.value = robotIds[nextIndex];
};

const handleExperimentFormOpened = () => {
  console.log("MQTTIntegration: handleExperimentFormOpened called");
  isExperimentFormOpen.value = true;
  toggleKeyboardCaptures(false);
};

const handleExperimentFormClosed = () => {
  console.log("MQTTIntegration: handleExperimentFormClosed called");
  isExperimentFormOpen.value = false;
  toggleKeyboardCaptures(true);
};

const toggleKeyboardCaptures = (enable) => {
  if (enable) {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
  } else {
    document.removeEventListener("keydown", handleKeyDown);
    document.removeEventListener("keyup", handleKeyUp);
  }
};

let simulatorWindow = null;

const openSimulator = (experimentId = null) => {
  if (mqttIntegrationData.value && !isConnected.value) {
    const token = AuthService.getToken();
    const windowName = `${token}sim`;
    let url = `https://sim.terracrawler.com?token=${token}`;
    if (experimentId) {
      url += `&experiment_id=${experimentId}`;
    }

    // Close existing window if open
    if (simulatorWindow && !simulatorWindow.closed) {
      simulatorWindow.close();
    }

    // Clear robots array
    robots.splice(0, robots.length);
    console.log("All robots removed before opening simulator");

    // Try to open the new window
    simulatorWindow = window.open(
      url,
      windowName,
      "width=800,height=600,resizable,scrollbars,status"
    );

    if (simulatorWindow) {
      simulatorWindow.focus();
    } else {
      console.error(
        "Failed to open simulator window. Please check your popup blocker settings."
      );
      // Optionally, show a user-friendly message
      alert(
        "Failed to open simulator window. Please check your popup blocker settings and try again."
      );
    }
  }
};

onMounted(async () => {
  await initializeTensorFlow();

  if (isTfReady.value && !agent.value) {
    await initializeModels();
    await loadModels();
  }

  // Load simulation mode from localStorage and update mainTopic
  isTestMode.value = localStorage.getItem("simulationMode") === "true";
  mainTopic.value = isTestMode.value ? "robotswarm-sim" : "robotswarm";
  console.log(
    `Initial mode: ${isTestMode.value ? "simulation" : "production"}. Main topic is '${
      mainTopic.value
    }'.`
  );

  window.addEventListener("online", handleOnline);
  window.addEventListener("offline", handleOffline);
  window.addEventListener("login-successful", handleLoginSuccess);
  window.addEventListener("initiate-mqtt-connection", handleInitiateMQTTConnection);
  window.addEventListener("user-login", handleUserLogin);
  window.addEventListener("user-logout", handleUserLogout);
  window.addEventListener("start-experiment-training", startExperimentTraining);
  window.addEventListener("keydown", handleKeyDown);
  window.addEventListener("keyup", handleKeyUp);
  window.addEventListener("experiment-form-opened", handleExperimentFormOpened);
  window.addEventListener("experiment-form-closed", handleExperimentFormClosed);

  // Check if user is already logged in and connect MQTT if so
  if (await AuthService.getCurrentUser()) {
    handleInitiateMQTTConnection();
  }

  fetchUserRobots();
});

onBeforeUnmount(() => {
  if (setupMQTT.value) {
    if (setupMQTT.value.reconnectInterval.value) {
      clearInterval(setupMQTT.value.reconnectInterval.value);
    }
    if (setupMQTT.value.client.value && setupMQTT.value.isConnected.value) {
      setupMQTT.value.client.value.disconnect();
    }
    Object.values(setupMQTT.value.robotIntervals).forEach((interval) => {
      if (interval.heartbeatTimer) {
        clearTimeout(interval.heartbeatTimer);
      }
    });
    clearTimeout(statusTimeout);
  }

  window.removeEventListener("online", handleOnline);
  window.removeEventListener("offline", handleOffline);
  window.removeEventListener("login-successful", handleLoginSuccess);
  window.removeEventListener("initiate-mqtt-connection", handleInitiateMQTTConnection);
  window.removeEventListener("user-login", handleUserLogin);
  window.removeEventListener("user-logout", handleUserLogout);
  window.removeEventListener("start-experiment-training", startExperimentTraining);
  window.removeEventListener("keydown", handleKeyDown);
  window.removeEventListener("keyup", handleKeyUp);
  window.removeEventListener("experiment-form-opened", handleExperimentFormOpened);
  window.removeEventListener("experiment-form-closed", handleExperimentFormClosed);

  // Close the simulator window if it's still open
  if (simulatorWindow && !simulatorWindow.closed) {
    simulatorWindow.close();
  }
});

// Expose necessary properties and methods
const exposedProperties = computed(() => {
  return {
    logs,
    robots,
    isTestMode,
    connectMQTT: setupMQTT.value ? setupMQTT.value.connectMQTT : null,
    isTfReady,
  };
});

const currentExperiment = ref(null);

const showExperimentForm = ref(false);

const mqttIntegrationData = computed(() => ({
  isTestMode: isTestMode.value,
  numRobots: isTestMode.value ? 3 : robots.length,
  robots: robots,
}));

const stopExperimentTraining = async (experiment) => {
  console.log("MQTTIntegration: stopExperimentTraining called", experiment);
  if (!experiment || !experiment._id) {
    console.error("Invalid experiment object:", experiment);
    throw new Error("Invalid experiment data");
  }

  try {
    let experimentToStop = experiment;
    if (currentExperiment.value && currentExperiment.value._id === experiment._id) {
      experimentToStop = currentExperiment.value;
    }

    console.log("MQTTIntegration: Stopping experiment", experimentToStop);

    if (experimentToStop.isSimulation) {
      // Send MQTT command to stop the experiment
      sendExperimentCommand(experimentToStop._id, "stop");

      // Send a stop command to the simulator
      if (experimentToStop.swarm_id) {
        sendCommand("stop_simulation");
      } else {
        console.error("No swarm_id found for the experiment");
      }

      // Remove all robots in simulation mode
      robots.splice(0, robots.length);
      console.log("All simulated robots removed");
    } else {
      // Send a stop command to all swarms
      sendCommand("stop");
    }

    // Always set the status to 'Stopped' when manually stopping the experiment
    const newStatus = "Stopped";

    // Update the experiment status
    const updatedExperiment = await updateExperiment(experimentToStop._id, {
      status: newStatus,
    });
    console.log("Updated experiment:", updatedExperiment);

    // Emit events
    emit("experiment-updated", updatedExperiment);
    emit("training-stopped", updatedExperiment);

    // Only set currentExperiment to null after all operations are complete
    if (
      currentExperiment.value &&
      currentExperiment.value._id === updatedExperiment._id
    ) {
      currentExperiment.value = null;
    }

    return updatedExperiment;
  } catch (error) {
    console.error("Failed to update experiment status:", error);
    throw error;
  } finally {
    isTraining.value = false;
  }
};

const userRobots = ref([]);

const fetchUserRobots = async () => {
  try {
    const response = await axios.get("/api/robots");
    userRobots.value = response.data;
  } catch (error) {
    console.error("Error fetching user robots:", error);
  }
};

const checkRobotOwnership = async (robotId, isSimulated) => {
  try {
    const response = await axios.post("/api/robots/check-ownership", {
      robot_id: robotId,
      is_simulated: isSimulated,
    });
    return response.data;
  } catch (error) {
    console.error("Error checking robot ownership:", error);
    return { owned: false };
  }
};

defineExpose({
  ...exposedProperties.value,
  startExperimentTraining,
  handleExperimentAdded,
  showExperimentForm,
  mqttIntegrationData,
  handleExperimentFormOpened,
  handleExperimentFormClosed,
  setSimulationMode,
  stopExperimentTraining,
  isTraining,
  openSimulator,
  toggleKeyboardCaptures,
  sendCommand,
  sendRandomCommands,
  sendStopCommand,
  userRobots,
  fetchUserRobots,
  checkRobotOwnership,
});
</script>

<style scoped>
#model-training {
  width: 400px;
  height: 300px;
}

.robot-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.robot-title {
  width: 100%;
}

.canvas-overlay-container {
  position: relative;
  width: 100%;
  margin-top: 0;
}

.robot-image,
.robot-canvas {
  width: 100%;
  height: 100%;
}

.robot-canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: transparent;
}

.robot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  border: 3px solid #ccc;
  margin-bottom: 10px;
  padding: 0;
  transition: all 0.3s ease;
}

canvas.robot-canvas,
img.robot-image {
  margin-bottom: 10px;
  border: 1px solid #ddd;
}

.logs {
  position: relative;
  background-color: #222;
  height: 20rem;
  overflow-y: auto;
}

.log-entry {
  padding: 2px 5px;
}

.log-entry i.fas {
  margin-right: 5px;
}

.log-entry pre,
.log-entry div {
  padding: 3px;
  font-family: "Courier New", monospace;
  overflow-x: auto;
  color: #ddd;
}

.log-entry .message {
  margin-left: 10px;
}

.log-error {
  color: #ff5555;
}

.log-notice {
  color: #ffcc55;
}

.log-send {
  color: #55ff55;
}

.log-receive {
  color: #55ddff;
}

.log-subscribe {
  color: #9575cd;
}

.log-unsubscribe {
  color: #ff7f50;
}

.timestamp {
  color: #888;
}

.topic {
  color: #ffcc55;
  font-weight: bold;
}

.payload {
  color: #9cdf55;
}

.control-buttons button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 16px;
  padding: 8px;
}

.control-buttons button:hover {
  background-color: #f0f0f0;
}

.robot-details {
  width: 100%;
}

.toggle-path {
  transition: background 0.3s ease-in-out;
}

.toggle-circle {
  transition: transform 0.3s ease-in-out;
}

input:checked ~ .toggle-path {
  background-color: #4d90fe;
}

input:checked ~ .toggle-circle {
  transform: translateX(1.25rem);
}

#tfjs-vis-model,
#tfjs-vis-metrics {
  height: 300px;
  border: 1px solid #ddd;
  margin-top: 10px;
  padding: 10px;
}

.connection-status-fixed {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: bold;
  transition: opacity 0.3s ease-in-out;
}

.connection-status-fixed.connected {
  background-color: #4caf50;
  color: white;
}

.connection-status-fixed.disconnected {
  background-color: #f44336;
  color: white;
}

.connection-status-fixed.connecting,
.connection-status-fixed.reconnecting {
  background-color: #ffa500;
  color: white;
}

.selected-robot {
  border: 3px solid #4caf50;
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
  padding: 0; /* Adjust padding to maintain consistent size */
}

.selected-robot .robot-title {
  background-color: #4caf50;
}

.control-button:disabled,
.control-button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
