<template>
  <div class="admin-page container mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4">
      {{ isAdmin ? "Admin Dashboard" : "User Dashboard" }}
    </h1>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <!-- User Management -->
      <div class="bg-white shadow rounded-lg p-4">
        <h2 class="text-xl font-semibold mb-2">User Management</h2>
        <button
          v-if="isAdmin"
          @click="showAddUserModal = true"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Add New User
        </button>
        <ul>
          <li
            v-for="user in users"
            :key="user._id"
            class="mb-2 flex justify-between items-center"
          >
            {{ user.username }}
            <div>
              <button
                @click="editUser(user)"
                class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2"
              >
                Edit
              </button>
              <button
                v-if="isAdmin"
                @click="deleteUser(user._id)"
                class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
              >
                Delete
              </button>
            </div>
          </li>
        </ul>
      </div>

      <!-- Experiment Tracking -->
      <div class="bg-white shadow rounded-lg p-4">
        <h2 class="text-xl font-semibold mb-2">Experiment Tracking</h2>
        <button
          @click="showAddExperimentModal = true"
          class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          New Experiment
        </button>
        <ul>
          <li v-for="experiment in experiments" :key="experiment._id" class="mb-2">
            <div class="flex justify-between items-center">
              <span>{{ experiment.name }} ({{ formatDate(experiment.date) }})</span>
              <div>
                <button
                  @click="viewExperimentDetails(experiment)"
                  class="bg-purple-500 hover:bg-purple-700 text-white font-bold py-1 px-2 rounded"
                >
                  View Details
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Add User Modal -->
    <modal v-if="showAddUserModal" @close="showAddUserModal = false">
      <template v-slot:header>
        <h3 class="text-lg font-semibold">Add New User</h3>
      </template>
      <template v-slot:body>
        <register-form @user-added="onUserAdded" :is-admin="isAdmin" />
      </template>
    </modal>

    <!-- Edit User Modal -->
    <modal v-if="showEditUserModal" @close="showEditUserModal = false">
      <template v-slot:header>
        <h3 class="text-lg font-semibold">Edit User</h3>
      </template>
      <template v-slot:body>
        <user-form
          :user="selectedUser"
          @user-updated="onUserUpdated"
          :is-admin="isAdmin"
        />
      </template>
    </modal>

    <!-- Add Experiment Modal -->
    <modal v-if="showAddExperimentModal" @close="showAddExperimentModal = false">
      <template v-slot:header>
        <h3 class="text-lg font-semibold">New Experiment</h3>
      </template>
      <template v-slot:body>
        <experiment-form @experiment-added="onExperimentAdded" />
      </template>
    </modal>

    <!-- Experiment Details Modal -->
    <modal v-if="selectedExperiment" @close="selectedExperiment = null">
      <template v-slot:header>
        <h3 class="text-lg font-semibold">
          Experiment Details: {{ selectedExperiment.name }}
        </h3>
      </template>
      <template v-slot:body>
        <experiment-details :experiment="selectedExperiment" />
      </template>
    </modal>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import Modal from "@/components/Modal.vue";
import RegisterForm from "@/components/RegisterForm.vue";
import UserForm from "@/components/UserForm.vue";
import ExperimentForm from "@/components/ExperimentForm.vue";
import ExperimentDetails from "@/components/ExperimentDetails.vue";
import { fetchUsers, deleteUser as deleteUserService } from "@/services/UserService";
import { fetchExperiments, addExperiment } from "@/services/ExperimentService";
import AuthService from "@/services/AuthService";

export default {
  name: "AdminPage",
  components: {
    Modal,
    RegisterForm,
    UserForm,
    ExperimentForm,
    ExperimentDetails,
  },
  setup() {
    const users = ref([]);
    const experiments = ref([]);
    const showAddUserModal = ref(false);
    const showEditUserModal = ref(false);
    const showAddExperimentModal = ref(false);
    const selectedExperiment = ref(null);
    const selectedUser = ref(null);
    const currentUser = ref(null);

    const isAdmin = computed(
      () => currentUser.value && currentUser.value.role === "admin"
    );

    onMounted(async () => {
      users.value = await fetchUsers();
      experiments.value = await fetchExperiments();
      currentUser.value = await AuthService.getCurrentUser();
    });

    const editUser = (user) => {
      selectedUser.value = user;
      showEditUserModal.value = true;
    };

    const onUserAdded = async (newUser) => {
      users.value.push(newUser);
      showAddUserModal.value = false;
    };

    const onUserUpdated = async (updatedUser) => {
      const index = users.value.findIndex((u) => u._id === updatedUser._id);
      if (index !== -1) {
        users.value[index] = updatedUser;
      }
      showEditUserModal.value = false;
    };

    const onExperimentAdded = async (newExperiment) => {
      const addedExperiment = await addExperiment(newExperiment);
      experiments.value.push(addedExperiment);
      showAddExperimentModal.value = false;
    };

    const viewExperimentDetails = (experiment) => {
      selectedExperiment.value = experiment;
    };

    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleDateString();
    };

    const deleteUser = async (userId) => {
      if (confirm("Are you sure you want to delete this user?")) {
        try {
          await deleteUserService(userId);
          users.value = users.value.filter((user) => user._id !== userId);
        } catch (error) {
          console.error("Failed to delete user:", error);
          // Handle error (e.g., show error message to user)
        }
      }
    };

    return {
      users,
      experiments,
      showAddUserModal,
      showEditUserModal,
      showAddExperimentModal,
      selectedExperiment,
      selectedUser,
      isAdmin,
      editUser,
      deleteUser,
      onUserAdded,
      onUserUpdated,
      onExperimentAdded,
      viewExperimentDetails,
      formatDate,
    };
  },
};
</script>
