<template>
  <div class="container mx-auto p-4">
    <MQTTIntegration
      ref="mqttIntegrationRef"
      @robotIdsUpdated="updateRobots"
      @sendCommand="handleCommand"
      @experiment-added="handleExperimentAdded"
      @experiment-updated="handleExperimentUpdated"
      @training-started="handleTrainingStarted"
      @training-stopped="handleTrainingStopped"
    />

    <!-- Simulator iframe -->
    <div v-if="showSimulator" class="mt-6 mb-6">
      {{ console.log('Template: Rendering simulator iframe, simulatorUrl:', simulatorUrl) }}
      <iframe
        :key="iframeKey"
        :src="simulatorUrl"
        class="w-full h-[600px] border-4 border-blue-500 rounded-lg"
        frameborder="0"
      ></iframe>
    </div>

    <!-- Experiment List -->
    <div class="bg-white shadow rounded-lg p-4 mt-4">
      <ExperimentList
        :experiments="filteredExperiments"
        :openSimulator="mqttIntegrationRef?.openSimulator"
        :mqttIntegrationData="mqttIntegrationData"
        :getConnectedRobots="getConnectedRobots"
        @experiment-added="handleExperimentAdded"
        @experiment-updated="handleExperimentUpdated"
        @experiment-list-updated="fetchExperimentsList"
        @train-experiment="handleTrainExperiment"
        @stop-experiment="handleStopExperiment"
        @retrain-experiment="handleRetrainExperiment"
        @resume-experiment="handleResumeExperiment"
        @delete-experiment="handleDeleteExperiment"
        @view-experiment-details="showExperimentDetails"
        @save-experiment-models="handleSaveExperimentModels"
        @load-experiment-models="handleLoadExperimentModels"
        @experiment-form-opened="handleExperimentFormOpened"
        @experiment-form-closed="handleExperimentFormClosed"
      />
    </div>

    <!-- Experiment Details Modal -->
    <Modal v-if="selectedExperiment" @close="selectedExperiment = null">
      <template v-slot:header>
        <h2 class="text-2xl font-bold text-gray-800">{{ selectedExperiment.name }}</h2>
      </template>
      <template v-slot:body>
        <ExperimentDetails :experiment="selectedExperiment" @experiment-updated="handleExperimentUpdated" />
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import MQTTIntegration from "@/components/MQTTIntegration.vue";
import ExperimentList from "@/components/ExperimentList.vue";
import Modal from "@/components/Modal.vue";
import ExperimentDetails from "@/components/ExperimentDetails.vue";
import { fetchExperiments } from "@/services/ExperimentService";
import AuthService from "@/services/AuthService";

const mqttIntegrationRef = ref(null);
const experiments = ref([]);
const robots = ref([]);
const currentUser = ref(null);
const selectedExperiment = ref(null);
const isLoading = ref(true);

const mqttIntegrationData = computed(() => {
  const data = mqttIntegrationRef.value ? mqttIntegrationRef.value.mqttIntegrationData : null;
  console.log("MQTT Integration Data:", data);
  return data;
});

const filteredExperiments = computed(() => {
  console.log("Computing filtered experiments");
  console.log("Current user:", currentUser.value);
  console.log("Is test mode:", mqttIntegrationRef.value?.isTestMode);
  console.log("All experiments:", experiments.value);

  if (!currentUser.value || !currentUser.value._id) {
    console.log("No user logged in or user ID is missing, returning empty experiments");
    return [];
  }

  const isSimMode = mqttIntegrationRef.value?.isTestMode;
  console.log("Simulation mode:", isSimMode);

  const filtered = experiments.value.filter(exp => {
    console.log("Checking experiment:", exp);
    console.log("User IDs:", exp.user_ids);
    console.log("Current user ID:", currentUser.value._id);
    console.log("Is simulation match:", exp.isSimulation === isSimMode);

    return exp.user_ids && 
           Array.isArray(exp.user_ids) && 
           exp.user_ids.includes(currentUser.value._id) && 
           exp.isSimulation === isSimMode;
  });

  console.log("Filtered experiments:", filtered);
  return filtered;
});

const showSimulator = ref(false);
const simulatorUrl = ref('');
const iframeKey = ref(0);

const fetchExperimentsList = async () => {
  console.log("Fetching experiments list...");
  isLoading.value = true;
  try {
    const fetchedExperiments = await fetchExperiments();
    console.log("Fetched experiments:", fetchedExperiments);
    experiments.value = fetchedExperiments;
    console.log("Experiments set in ref:", experiments.value); // Add this line
  } catch (error) {
    console.error("Failed to fetch experiments:", error);
  } finally {
    isLoading.value = false;
  }
};

const handleExperimentAdded = async (newExperiment) => {
  try {
    experiments.value.push(newExperiment);
    await fetchExperimentsList(); 
  } catch (error) {
    console.error("Failed to add experiment:", error);
  }
};

const handleTrainExperiment = async (experiment) => {
  console.log("HomePage: handleTrainExperiment called", experiment);
  if (!experiment || !experiment._id) {
    console.error("Invalid experiment object:", experiment);
    return;
  }

  try {
    // Check if any experiment is currently training
    const trainingExperiment = experiments.value.find(exp => exp.status === "Running");
    if (trainingExperiment) {
      // Stop the current training before starting a new one
      await handleStopExperiment(trainingExperiment);
    }

    // Check if it's a real-world experiment
    if (!experiment.isSimulation) {
      const connectedRobots = getConnectedRobots();
      if (connectedRobots.length < experiment.numRobots) {
        alert(`[Error] Cannot start real-world training. Expected ${experiment.numRobots} robots, but ${connectedRobots.length} are connected.`);
        return;
      }
    }

    // If we've passed the check (or it's a simulation), proceed with starting the experiment
    if (mqttIntegrationRef.value) {
      console.log("HomePage: Calling startExperimentTraining");
      const updatedExperiment = await mqttIntegrationRef.value.startExperimentTraining(experiment);
      if (updatedExperiment)  {
        console.log("Experiment updated after starting training:", updatedExperiment);
        handleExperimentUpdated(updatedExperiment);
      } else {
        console.warn("startExperimentTraining did not return an updated experiment");
      }
    } else {
      console.error("MQTTIntegration component not available");
    }
  } catch (error) {
    console.error("Failed to start training experiment:", error);
    alert(`Failed to start training experiment: ${error.message}`);
  }
};

const handleExperimentUpdated = (updatedExperiment) => {
  console.log("Handling experiment update:", updatedExperiment);
  const index = experiments.value.findIndex(exp => exp._id === updatedExperiment._id);
  if (index !== -1) {
    console.log("Updating experiment at index:", index);
    experiments.value[index] = { ...experiments.value[index], ...updatedExperiment };
  } else {
    console.log("Experiment not found in list, adding it:", updatedExperiment);
    experiments.value.push(updatedExperiment);
  }
  console.log("Updated experiments list:", experiments.value);
};

const handleStopExperiment = async (experiment) => {
  console.log("HomePage: handleStopExperiment called", experiment);
  if (!experiment || !experiment._id) {
    console.error("Invalid experiment object:", experiment);
    return;
  }

  try {
    if (mqttIntegrationRef.value) {
      console.log("Calling stopExperimentTraining with experiment:", experiment);
      const stoppedExperiment = await mqttIntegrationRef.value.stopExperimentTraining(experiment);
      console.log("Stopped experiment:", stoppedExperiment);
      if (stoppedExperiment && stoppedExperiment._id) {
        handleExperimentUpdated(stoppedExperiment);
      } else {
        console.error("stopExperimentTraining returned invalid data");
        // Fetch the latest experiment data
        await fetchExperimentsList();
      }
    } else {
      console.error("MQTTIntegration component not available");
    }

    showSimulator.value = false;
    simulatorUrl.value = '';
  } catch (error) {
    console.error("Failed to stop experiment:", error);
    alert(`Failed to stop experiment: ${error.message}`);
    // Fetch the latest experiment data
    await fetchExperimentsList();
  }
};

const handleRetrainExperiment = async (experiment) => {
  console.log("HomePage: handleRetrainExperiment called", experiment);
  // Stop any running experiment before retraining
  const runningExperiment = experiments.value.find(exp => exp.status === "Running");
  if (runningExperiment) {
    await handleStopExperiment(runningExperiment);
  }
  
  if (mqttIntegrationRef.value) {
    console.log("HomePage: Calling startExperimentTraining with retrain flag");
    const updatedExperiment = await mqttIntegrationRef.value.startExperimentTraining(experiment, true);
    if (updatedExperiment) {
      handleExperimentUpdated(updatedExperiment);
    }
  } else {
    console.error("MQTTIntegration component not available");
  }
};

const handleResumeExperiment = async (experiment) => {
  // Stop any running experiment before resuming
  const runningExperiment = experiments.value.find(exp => exp.status === "Running");
  if (runningExperiment && runningExperiment._id !== experiment._id) {
    await handleStopExperiment(runningExperiment);
  }

  if (mqttIntegrationRef.value) {
    const updatedExperiment = await mqttIntegrationRef.value.startExperimentTraining(experiment);
    if (updatedExperiment) {
      handleExperimentUpdated(updatedExperiment);
    }
  } else {
    console.error("MQTTIntegration component not available");
  }
};

const handleDeleteExperiment = async (experiment) => {
  // If the experiment to be deleted is currently running, stop it first
  if (experiment.status === "Running") {
    await handleStopExperiment(experiment);
  }
  experiments.value = experiments.value.filter(e => e._id !== experiment._id);
};

const showExperimentDetails = (experimentDetails) => {
  selectedExperiment.value = experimentDetails;
};

const handleSaveExperimentModels = async () => {
  console.log("Experiment models saved successfully");
};

const handleLoadExperimentModels = async () => {
  console.log("Experiment models loaded successfully");
};

const updateRobots = (newRobots) => {
  robots.value = newRobots;
};

const handleCommand = (command) => {
  console.log("Command received", command);
};

const handleTrainingStarted = (experiment) => {
  console.log("HomePage: handleTrainingStarted called", experiment);
  if (experiment.isSimulation) {
    console.log("HomePage: Setting up simulator for simulation experiment");
    showSimulator.value = true;
    const token = AuthService.getToken();
    simulatorUrl.value = `https://sim.terracrawler.com?token=${token}&experiment_id=${experiment._id}`;
    console.log("HomePage: Simulator URL set", simulatorUrl.value);
    console.log("HomePage: showSimulator set to", showSimulator.value);
    iframeKey.value++; // Force refresh the iframe
  } else {
    console.log("HomePage: Not a simulation experiment, simulator not shown");
  }
};

const handleTrainingStopped = async (stoppedExperiment) => {
  console.log("HomePage: handleTrainingStopped called", stoppedExperiment);
  if (stoppedExperiment && stoppedExperiment._id) {
    console.log("Updating experiment in list with status:", stoppedExperiment.status);
    handleExperimentUpdated(stoppedExperiment);
  } else {
    console.warn("handleTrainingStopped called with invalid experiment data");
    // Fetch the latest experiment data
    await fetchExperimentsList();
  }
  // Add a delay before hiding the simulator
  setTimeout(() => {
    showSimulator.value = false;
    simulatorUrl.value = '';
    console.log("HomePage: showSimulator set to", showSimulator.value);
  }, 5000); // 5 seconds delay
};

const getConnectedRobots = () => {
  return mqttIntegrationRef.value ? mqttIntegrationRef.value.robots : [];
};

const handleExperimentFormOpened = () => {
  console.log('HomePage: Experiment form opened');
  if (mqttIntegrationRef.value) {
    mqttIntegrationRef.value.handleExperimentFormOpened();
  }
};

const handleExperimentFormClosed = () => {
  console.log('HomePage: Experiment form closed');
  if (mqttIntegrationRef.value) {
    mqttIntegrationRef.value.handleExperimentFormClosed();
  }
};

const setCurrentUser = async () => {
  const user = await AuthService.getCurrentUser();
  if (user && user._id) {
    currentUser.value = user;
    console.log("Current user set:", currentUser.value);
    console.log("User ID:", currentUser.value._id);
  } else {
    console.log("No user logged in or user ID is missing");
    currentUser.value = null;
    //await router.push('/login');
  }

  return currentUser.value;
};

// Watch for changes in the authentication state
watch(async () => await AuthService.getCurrentUser(), async (user) => {
  console.log("Auth state changed. Current user:", user);
  if (user) {
    await setCurrentUser();
    await fetchExperimentsList();
  } else {
    currentUser.value = null;
    experiments.value = [];
  }
});

onMounted(async () => {
  console.log("HomePage mounted");
  const user = await setCurrentUser();
  console.log("Current user after setCurrentUser:", user);
  if (user) {
    console.log("User is authenticated, fetching experiments");
    await fetchExperimentsList();
  } else {
    console.log("User is not authenticated");
  }
  
  if (mqttIntegrationRef.value && mqttIntegrationRef.value.openSimulator) {
    console.log("openSimulator function is available");
  } else {
    console.log("openSimulator function is not available");
  }

  // Listen for login and logout events
  window.addEventListener('user-login', async () => {
    await setCurrentUser();
    await fetchExperimentsList();
  });
  window.addEventListener('user-logout', () => {
    currentUser.value = null;
    experiments.value = [];
  });
});

// Clean up event listeners
onUnmounted(() => {
  window.removeEventListener('user-login', setCurrentUser);
  window.removeEventListener('user-logout', () => {
    currentUser.value = null;
    experiments.value = [];
  });
});

defineEmits(['experiment-form-opened', 'experiment-form-closed']);
</script>

<style></style>
