<template>
  <div id="app">
    <nav class="bg-gray-800 p-4">
      <div class="container mx-auto flex justify-between items-center">
        <router-link to="/" class="text-white font-bold"
          >Robot Swarm Control Panel</router-link
        >
        <div class="flex items-center">
          <template v-if="currentUser">
            <router-link v-if="isAdmin" to="/admin" class="text-white mr-4"
              >Admin</router-link
            >
            <button @click="handleLogout" class="text-white">Logout</button>
          </template>
          <router-link v-else to="/login" class="text-white">Login</router-link>
        </div>
      </div>
    </nav>
    <div
      v-if="errorMessage"
      class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
      role="alert"
    >
      <strong class="font-bold">Error!</strong>
      <span class="block sm:inline">{{ errorMessage }}</span>
    </div>
    <router-view 
      @login-success="updateCurrentUser" 
      @experiment-form-opened="handleExperimentFormOpened"
      @experiment-form-closed="handleExperimentFormClosed"
    />
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import AuthService from "@/services/AuthService";

export default {
  name: "App",
  setup() {
    const router = useRouter();
    const errorMessage = ref("");

    const currentUser = ref(null);
    const isAdmin = computed(() => currentUser.value?.role === "admin" || false);

    const updateCurrentUser = async () => {
      currentUser.value = await AuthService.getCurrentUser();
      console.log("Current user updated:", currentUser.value);
    };

    const handleLogin = () => {
      updateCurrentUser();
      router.push("/"); // Redirect to home page after login
    };

    watch(
      async () => await AuthService.getCurrentUser(),
      (newUser) => {
        currentUser.value = newUser;
      }
    );

    const handleLogout = async () => {
      try {
        if (window.mqttClient && typeof window.mqttClient.disconnect === "function") {
          window.mqttClient.disconnect();
        }
        await AuthService.logout();
      } catch (error) {
        console.error("Logout process encountered an error:", error);
      } finally {
        currentUser.value = null;
        if (router.currentRoute.value.name !== "login") {
          router.push("/login");
        }
      }
    };

    const handleExperimentFormOpened = () => {
      console.log('App: Experiment form opened');
      window.dispatchEvent(new CustomEvent('experiment-form-opened'));
    };

    const handleExperimentFormClosed = () => {
      console.log('App: Experiment form closed');
      window.dispatchEvent(new CustomEvent('experiment-form-closed'));
    };

    onMounted(() => {
      updateCurrentUser();
      window.addEventListener("user-login", handleLogin);
      window.addEventListener("user-logout", updateCurrentUser);
    });

    onUnmounted(() => {
      window.removeEventListener("user-login", handleLogin);
      window.removeEventListener("user-logout", updateCurrentUser);
    });

    return {
      currentUser,
      isAdmin,
      handleLogout,
      errorMessage,
      handleExperimentFormOpened,
      handleExperimentFormClosed,
    };
  },
};
</script>
