<template>
  <form @submit.prevent="submitForm" class="space-y-4">
    <div v-for="field in formFields" :key="field.key">
      <label :for="field.key" class="block text-sm font-medium text-gray-700">{{
        field.label
      }}</label>
      <input
        v-if="field.type !== 'textarea'"
        :id="field.key"
        v-model="formData[field.key]"
        :type="field.type"
        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
      />
      <textarea
        v-else
        :id="field.key"
        v-model="formData[field.key]"
        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
      ></textarea>
    </div>
    <div>
      <label for="trainingAim" class="block text-sm font-medium text-gray-700"
        >Training Aim</label
      >
      <select
        v-model="formData.trainingAim"
        id="trainingAim"
        class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 rounded-md"
      >
        <option value="disperse">Disperse</option>
        <option value="gather">Gather</option>
        <option value="explore">Explore</option>
      </select>
    </div>
    <div>
      <label for="isSimulation" class="block text-sm font-medium text-gray-700"
        >Simulation Mode</label
      >
      <input
        id="isSimulation"
        v-model="formData.isSimulation"
        type="checkbox"
        class="mt-1"
        @change="updateFormFields"
      />
    </div>
    <button
      type="submit"
      :disabled="isSubmitting"
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    >
      {{ isSubmitting ? "Submitting..." : "Create Experiment" }}
    </button>
  </form>
</template>

<script setup>
import { reactive, watch, onMounted, ref } from "vue";

const props = defineProps({
  mqttIntegration: {
    type: Object,
    required: true,
  },
});

const formFields = [
  { key: "name", label: "Experiment Name", type: "text" },
  { key: "description", label: "Description", type: "textarea" },
  { key: "numRobots", label: "Number of Robots", type: "number" },
  { key: "numEpochs", label: "Number of Epochs", type: "number" },
];

const formData = reactive({
  name: "",
  description: "",
  numRobots: 5,
  numEpochs: 10,
  trainingAim: "disperse",
  isSimulation: false,
  submissionId: "",
});

const userEdited = ref({
  numRobots: false,
  numEpochs: false,
});

const updateFormData = () => {
  console.log("Updating form data with:", props.mqttIntegration);
  if (props.mqttIntegration) {
    formData.isSimulation = props.mqttIntegration.isTestMode;

    if (!userEdited.value.numRobots) formData.numRobots = 5; // Default value
    if (!userEdited.value.numEpochs) formData.numEpochs = 10; // Default value

    if (isDefaultName(formData.name) || formData.name.trim() === "") updateName();
    if (isDefaultDescription(formData.description) || formData.description.trim() === "")
      updateDescription();
    console.log(
      "Form data updated:",
      formData.name,
      formData.description,
      formData.numRobots,
      formData.numEpochs
    );
  } else {
    console.warn("mqttIntegration is undefined");
  }
};

const updateFormFields = () => {
  updateName();
  updateDescription();
};

const updateName = () => {
  formData.name = `Experiment_${formData.isSimulation ? 'Sim' : 'Real'}_${formData.numRobots}R_${formData.numEpochs}E`;
};

const updateDescription = () => {
  formData.description = `${formData.isSimulation ? 'Simulation' : 'Real-world'} experiment with ${formData.numRobots} robots and ${formData.numEpochs} epochs.`;
};

const isDefaultName = (name) => {
  const regex = /^Experiment_(Sim|Real)_\d+R_\d+E$/;
  return regex.test(name);
};

const isDefaultDescription = (description) => {
  const regex = /^(Simulation|Real-world) experiment with \d+ robots and \d+ epochs\.$/;
  return regex.test(description);
};

onMounted(() => {
  console.log("ExperimentForm mounted, props:", props);
  updateFormData();
});

watch(
  () => props.mqttIntegration,
  (newVal) => {
    console.log("mqttIntegration changed:", newVal);
    updateFormData();
  },
  { deep: true }
);

// Watch for changes in numRobots, numEpochs, isSimulation, and trainingAim
["numRobots", "numEpochs", "isSimulation", "trainingAim"].forEach((field) => {
  watch(
    () => formData[field],
    () => {
      userEdited.value[field] = true;
      if (isDefaultName(formData.name) || formData.name.trim() === "") updateName();
      if (
        isDefaultDescription(formData.description) ||
        formData.description.trim() === ""
      )
        updateDescription();
    }
  );
});

const submitForm = async () => {
  if (isSubmitting.value || submitAttempted.value) return;

  submitAttempted.value = true;
  isSubmitting.value = true;

  try {
    // Reset userEdited flags before submitting
    Object.keys(userEdited.value).forEach((key) => {
      userEdited.value[key] = false;
    });

    emit("experiment-added", {...formData});
  } catch (error) {
    console.error("Error submitting form:", error);
  } finally {
    isSubmitting.value = false;
    // We don't reset submitAttempted here to prevent multiple submissions
  }
};

// Reset submitAttempted when the form is closed
watch(
  () => props.showForm,
  (newValue) => {
    if (!newValue) {
      submitAttempted.value = false;
    }
  }
);

// Define emits
const emit = defineEmits(["experiment-added"]);

const isSubmitting = ref(false);
const submitAttempted = ref(false);

watch(
  () => props.experiments,
  (newExperiments) => {
    if (isSubmitting.value && newExperiments.length > 0) {
      isSubmitting.value = false;
    }
  },
  { deep: true }
);
</script>
