<template>
  <div class="register-container">
    <h2 class="text-xl font-semibold mb-4">Register New User</h2>
    <form @submit.prevent="handleRegister" class="space-y-4">
      <div>
        <label for="username" class="block text-sm font-medium text-gray-700"
          >Username</label
        >
        <input
          v-model="username"
          type="text"
          id="username"
          placeholder="Username"
          required
          class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
        <input
          v-model="email"
          type="email"
          id="email"
          placeholder="Email"
          required
          class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div>
        <label for="password" class="block text-sm font-medium text-gray-700"
          >Password</label
        >
        <input
          v-model="password"
          type="password"
          id="password"
          placeholder="Password"
          required
          class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div v-if="isAdmin">
        <label for="role" class="block text-sm font-medium text-gray-700"
          >User Role</label
        >
        <select
          v-model="role"
          id="role"
          class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="user">User</option>
          <option value="admin">Admin</option>
        </select>
      </div>
      <button
        type="submit"
        class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Register
      </button>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import { addUser } from "@/services/UserService";

export default {
  name: "RegisterForm",
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["user-added"],
  setup(props, { emit }) {
    const username = ref("");
    const email = ref("");
    const password = ref("");
    const role = ref("user");

    const handleRegister = async () => {
      try {
        const newUser = await addUser({
          username: username.value,
          email: email.value,
          password: password.value,
          role: role.value,
        });
        emit("user-added", newUser);
        // Reset form
        username.value = "";
        email.value = "";
        password.value = "";
        role.value = "user";
      } catch (error) {
        console.error("Registration failed:", error);
        // Handle error (e.g., show error message to user)
      }
    };

    return {
      username,
      email,
      password,
      role,
      handleRegister,
    };
  },
};
</script>

<style scoped>
.register-container {
  max-width: 300px;
  margin: 0 auto;
}
</style>
